<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class="wp-image-23580 aligncenter" src="@/assets/news/20/1.png" alt="图片" width="717" height="281"  sizes="(max-width: 717px) 100vw, 717px"></p>
        <p><span style="color: #0000ff;"><strong>百万移民计划，不止是“百万”</strong></span></p>
        <p>加拿大当地时间2月14日，联邦移民局终于公布了2022-2024的移民计划，未来三年将接收超过120万名新移民。2022年的登陆目标为431,645名永久居民，比原先的计划增加了20,645个名额。省提名类别增加了12,500个名额，家庭团聚移民的配额增加至105,000 个。</p>
        <p>加拿大一直在大力发展“百万移民”计划，不断加强吸收新移民的力度。新发布的“百万移民”计划显示，已超出原计划数量，预计接收新移民人数再创新高！</p>
        <p><span style="color: #0000ff;"><strong>配额大幅增加</strong></span></p>
        <p>在2022年 – 2024年的移民水平计划中，预计接收新移民人数目标为：</p>
        <p>2022 年：431,645 名永久居民 ；</p>
        <p>2023 年：447,055 名永久居民 ；</p>
        <p>2024 年：451,000 名永久居民 。</p>
        <p><img loading="lazy" class="wp-image-23581 aligncenter" src="@/assets/news/20/2.png" alt="图片" width="714" height="430"  sizes="(max-width: 714px) 100vw, 714px"></p>
        <p>与2020年10月发布的2021-2023移民计划相比，新的计划表中2022年的总体移民配额增加了20,645个。</p>
        <p>经济类移民配额总体配额变化不明显，只增加了350个，以此满额增量计算，增加可能达到1,000个。</p>
        <p>家庭类位申请人，只增加了1,500个，以此满额增量计算，增加可能达到4,000个。</p>
        <p><span style="color: #0000ff;"><strong>2022年省提名配额猛增</strong></span></p>
        <p>2022 年，预计约有56%的新移民将通过经济类移民项目获得永居身份，主要通过EE联邦快速通道、省提名计划 (PNP) 等。对比其他类别，此次省提名配额增长迅速，2022年目标名额为83,500，比此前计划（81,500）增加了2,000个，但考虑到往年各省公布的实际提名人数都高于配额标准，以此满额增量计算，增加可能达到12,500个。</p>
        <p><img loading="lazy" class="wp-image-23582 aligncenter" src="@/assets/news/20/3.png" alt="图片" width="727" height="428"  sizes="(max-width: 727px) 100vw, 727px"></p>
        <p style="text-align: center;">（加拿大2022-2024年移民接纳计划）</p>
        <p>由于安省劳动力严重紧缺，所以基本每年安省省提名项目都在再额外向联邦申请6.5%-9.3%的配额。</p>
        <p><img loading="lazy" class="wp-image-23583 aligncenter" src="@/assets/news/20/4.png" alt="图片" width="751" height="1567"  sizes="(max-width: 751px) 100vw, 751px"></p>
        <p>安省在所有加拿大省份中占有更多省提名配额，并且一直持上升趋势，而且安省雇主担保项目一直是备受关注的。</p>
        <p>疫情期间，虽然暂时不能自由通行，但由于加拿大移民局不断加强吸收新移民的力度，促使了政策的临时放宽，预期在疫情缓解、边境开放之后，也将迎来境外申请人的高峰期。所以，对于一直观望加拿大的境外申请人，可以抓住这段政策的红利期，尽快上车。</p>
        <p style="text-align: center;"><span style="color: #0000ff;"><strong>通往加拿大<br>
</strong></span><br>
          <img loading="lazy" class="alignnone  wp-image-23584" src="@/assets/news/20/5.jpg" alt="图片" width="627" height="418"  sizes="(max-width: 627px) 100vw, 627px"></p>
        <p><span style="color: #0000ff;"><strong>安省雇主担保移民</strong></span></p>
        <p><strong>安省雇主担保移民项目优势</strong></p>
        <p>一人申请，全家移民</p>
        <p>无需投资，无需面试，全程在国内提交申请</p>
        <p>没有资产要求，无需解释资金来源</p>
        <p>EOI系统对学历和语言没有打分</p>
        <p>3-4个月获批省提名，可工签提前登陆安省，享受加拿大优质教育资源</p>
        <p><strong>安省雇主担保移民项目申请条件</strong></p>
        <p>针对人群：由资深经验的国内人士（建议50岁以下）</p>
        <p>学历要求：国内大专以上（建议）</p>
        <p>工作经验：申请前5年内有2年的有效工作经验（必须）</p>
        <p><strong>其他要求：</strong></p>
        <p>1、申请人在获得一份符合职业类别NOC0/A/B的全职工作；</p>
        <p>2、工资符合同省份范围内该职位的普遍公司标准；</p>
        <p>3、申请前2年有效工作经验与目标职业的NOC类别相同；</p>
        <p><span style="color: #0000ff;"><strong>BC省雇主担保移民</strong></span></p>
        <p><strong>BC省雇主担保移民项目优势</strong></p>
        <p>1、无资产要求，也无需解释资金来源</p>
        <p>2、一人申请，全家移民</p>
        <p>3、一步到位，移居温哥华</p>
        <p><strong>BC省雇主担保移民项目申请条件</strong></p>
        <p>1、至少 2 年直接相关的工作经验</p>
        <p>2、语言 : 建议 CLB6 起（听 5.5 说 5.5 读 5 写 5.5）</p>
        <p>3、学历 : 建议本科起</p>
        <p>4、足够支撑一年生活的资产证明（每人 5 万 RMB）</p>
        <p>5、获得一份 BC 省雇主提供的全职长期的 Job Offer，职位必须属于 NOC 职业分类中0/A/B 类</p>
        <p>6、Offer 上的薪资水平应与 BC 省同等职位的薪水持平</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news20",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:20,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>